"use client";
import Image from "next/image";
import clsx from "clsx";
import { TutorialType } from "@type/types";
import { useState } from "react";
import LocalizedLink from "../link/link";
import { useTranslations, useLocale } from "next-intl";

interface LearningBoardProps {
  tutorials: TutorialType[];
}

const sectionInfo: { title: string; icon: string } = {
  title: "learnTrading",
  icon: "/svg/icon/academic.icon.svg",
};
const availableLevels = ["BEGINNER", "INTERMEDIATE", "ADVANCED"];
export default function LearningBoard({ tutorials }: LearningBoardProps) {
  const [selectedLevel, setSelectedLevel] = useState<string>(availableLevels[0]);
  const locale = useLocale();
  const [filteredTutorials, setFilteredTutorials] = useState<TutorialType[]>(
    tutorials.filter((tutorial) => tutorial.level === availableLevels[0])
  );
  const common = useTranslations("common");
  const educationTranslation = useTranslations("educationSection");
  const changeTab = (level: string) => {
    setFilteredTutorials(tutorials.filter((tutorial) => tutorial.level === level));
    setSelectedLevel(level);
  };
  return (
    <div
      className={clsx(
        "flex flex-col border border-color-light border-solid rounded-md p-4  w-[100%] self-center overflow-hidden",
        // responsive
        "sm:flex-1"
      )}
    >
      <div className={clsx("flex flex-col items-start justify-start mb-6 gap-6")}>
        <div className="flex items-center justify-start gap-4">
          <Image src={sectionInfo.icon} alt={`market icon`} width={18} height={18} />
          <span className="text-md font-bold">{educationTranslation(sectionInfo.title)}</span>
        </div>
        <div className={clsx("flex flex-row items-center justify-start gap-4")}>
          {availableLevels.map((level) => (
            <button
              onClick={() => changeTab(level)}
              className={clsx(
                "text-xs py-1 px-3",
                level === selectedLevel ? "bg-color-primary rounded" : ""
              )}
              key={level}
            >
              <span
                className={clsx("text-xs", level === selectedLevel ? "text-color-primary" : "")}
              >
                {common(level.toLowerCase())}
              </span>
            </button>
          ))}
        </div>
      </div>

      <div
        className={clsx(
          "flex flex-row flex-wrap items-stretch justify-start mb-6 gap-7 mt-4 overflow-x-auto",
          "sm:flex-no-wrap"
        )}
      >
        {filteredTutorials.map((tutorial) => (
          <div
            key={tutorial.id}
            className={clsx(
              "flex flex-initial flex-col items-start justify-start gap-2 min-w-[150px] w-[150px]"
            )}
          >
            <LocalizedLink
              href={`/education/${tutorial.category}/${tutorial.slug}`}
              className={clsx("w-[100%]", "sm:max-w-[200px] sm:max-h-[160px]")}
            >
              <Image
                // className={clsx("rounded w-[100%]", "sm:max-w-[200px] sm:max-h-[160px]")}
                src={process.env.NEXT_PUBLIC_STORAGE_URL + tutorial.thumbnailImage}
                alt={`market icon`}
                width={200}
                height={160}
              />
            </LocalizedLink>
            <span className="text-xs ml-1">
              {tutorial.tutorialMetaData?.find((meta) => meta.language === locale)?.title}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
